import React, { useState, useEffect } from "react";
import Navbar from "../../admin/Navbar";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import Footer from "../../Footer";
import ashim from "../../assests/AshimPhoto.png";
import orcid from "../../assests/orcid_1.png";
import scopus from "../../assests/Scopus.png";
import Topbar from "../landing/Topbar";
import EducationalDetails from "./biosketch/EducationalDetails";
import AdministrativePositions from "./biosketch/AdministrativePositions";
import Expertise from "./biosketch/Expertise";
import ProfessionalBackground from "./biosketch/ProfessionalBackground";
import PersonalDetails from "./biosketch/PersonalDetails";
import Honours from "./Honour/Honours";
import Membership from "./Honour/Membership";
import Publication from "./research/Publication";
import StudyMaterials from "./studymaterials/StudyMaterials";
import ResearchProjects from "./research/ResearchProjects";
import MasterStudents from "./supervision/MasterStudents";
import Phd from "./supervision/Phd";
import Collab from "./research/Collab";
import Event from "./Misc/Event";
import Visit from "./Misc/Visit";
import Misc from "./Misc/Misc";
import Breadcrumbs from "../../components/Breadcrumbs";
import Spinner from "../facultydashboard/formGroup/Spinner";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BsFillPersonFill } from "react-icons/bs";
import AddClass from "./liveclass/AddClass";
import { Widget } from "@uploadcare/react-widget";
import ProfileId from "./biosketch/ProfileId";

function FacultyDashboard() {
  const navigate = useNavigate();
  const [dtailCard, setDtailCard] = useState("1");
  const [state, setState] = useState({});
  const [loader, setLoader] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);

  // // /api/v1/user-list
  // console.log(state)
  const authFaculty = () => {
    axios
      .get("/api/v1/profile")
      .then(function (response) {
        // handle success
        setState(response.data.user);
        setLoader(false);
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        setLoader(false);
        console.log(error);
      });
  };
  const editUser = (id) => {
    axios
      .put(`/api/v1/users/${id}`, {
        profilePic: uploadedFile,
      })
      .then(function (response) {
        // console.log(response);
        authFaculty();
        setUploadedFile();
        alert(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.errors);
      });
  };
  // console.log(state);
  useEffect(() => {
    authFaculty();
  }, []);
  return (
    <div>
      {loader && <Spinner />}

      {state && (
        <div style={{ width: "100%" }}>
          <Navbar />
          <Breadcrumbs
            page="Faculty Dashboard"
            pagelink="#"
            sublink="#"
            subpage="Dashboard"
          />
          <div className="bg-[#f7f7f7]">
            <div className="pt-4 pb-4 bg-[#f7f7f7]">
              <div className="faculty bg-[#f7f7f7]  gap-8  m-2 sm:m-[4.3rem] items-start">
                <div className="w-[] bg-white rounded-md m-auto">
                  <img
                    className="w-[15rem] h-[15rem]"
                    alt="profile pic"
                    src={uploadedFile ? uploadedFile : state.profilePic}
                  />
                  <div>
                    <span className="">
                      {uploadedFile ? (
                        <div className="flex gap-1 py-2">
                          <button
                            className="px-2 py-1 font-semibold bg-red-500 border-2 border-black "
                            onClick={() => {
                              setUploadedFile();
                            }}
                          >
                            Discard
                          </button>
                          <button
                            className="px-2 py-1 font-semibold bg-green-500 border-2 border-black "
                            onClick={() => {
                              editUser(state._id);
                            }}
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        <p className="py-2 font-semibold">Update Photo</p>
                      )}{" "}
                    </span>
                    <div className="bg-[#e5e7eb]  text-black-500">
                      <Widget
                        publicKey="1e63758e5c94313da33d"
                        onChange={(info) => setUploadedFile(info.cdnUrl)}
                      />
                    </div>
                  </div>
                </div>
                <div className="rounded-md bg-[#f7f7f7]">
                  <h1 className="flex justify-between font-bold text-black text-[1.2rem]">
                    {state.name}
                    <p
                      onClick={() => navigate("/logout")}
                      className="cursor-pointer my-auto  px-4 py-2  font-light text-sm rounded-md   hover:text-red-600 border  border-black hover:border-red-600"
                    >
                      Log Out{" "}
                    </p>{" "}
                  </h1>

                  <div className="mt-2 text-[0.9rem]">
                    {state.designation} at Bhupendra Narayan Mandal University,
                    Madhepura
                  </div>
                  <div className="flex item-center mt-6 gap-6">
                    <MdEmail className="text-red-500 text-[1.8rem] " />
                    <div>{state.email}</div>
                  </div>
                  <div className="flex item-center mt-6 gap-6">
                    <IoMdCall className="text-red-500 text-[1.8rem] " />
                    <div>
                      <span className="text-red-500 font-bold">+91</span>{" "}
                      {state.mobileNo}{" "}
                      <span className="text-red-500 font-bold"></span>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-end">
                    {state.profileLink ? (
                      <>
                        <span className="m-3 flex-1  inline-block">
                          <Link
                            to={`${state.profileLink}`}
                            target="_blank"
                            className="text-sm   flex"
                          >
                            <BsFillPersonFill className="my-auto text-lg" />
                            <span className="my-auto m-2">
                              Profile Link{" "}
                            </span>{" "}
                          </Link>
                        </span>{" "}
                      </>
                    ) : null}
                    {/* <a
  className="group relative inline-block overflow-hidden border border-indigo-600 px-8 py-3 focus:outline-none focus:ring"
 onClick={myFunction}
>
  <span
    className="absolute inset-x-0 bottom-0 h-[2px] bg-indigo-600 transition-all group-hover:h-full group-active:bg-indigo-500"
  ></span>

  <span
    className="relative text-sm font-medium text-indigo-600 transition-colors group-hover:text-white"
  >
    Start Class
  </span>
</a> */}
                    {/* :ETHEREUM 1 */}
                    {state.orcidId && (
                      <span className="m-3 inline-block">
                        <Link
                          to={`https://orcid.org/${state.orcidId}`}
                          target="_blank"
                          className="text-sm  flex"
                        >
                          <img src={orcid} alt="logo" className="w-1/6 " />{" "}
                          <span className="my-auto m-2">Orcid Id</span>{" "}
                        </Link>{" "}
                        <Link
                          to={`https://orcid.org/${state.orcidId}`}
                          target="_blank"
                        >
                          <p className="hover:underline hover:text-blue-600">
                            {state.orcidId}
                          </p>
                        </Link>
                      </span>
                    )}
                    {state.scopusId && (
                      <span className="m-3 inline-block">
                        <Link
                          to={`https://www.scopus.com/authid/detail.uri?authorId=${state.scopusId}`}
                          target="_blank"
                          className="text-sm   flex"
                        >
                          <img src={scopus} className="w-1/6  " />{" "}
                          <span className="my-auto text-black m-2">
                            Scopus Id
                          </span>{" "}
                        </Link>{" "}
                        <Link
                          to={`https://www.scopus.com/authid/detail.uri?authorId=${state.scopusId}`}
                          target="_blank"
                        >
                          <p className="hover:underline hover:text-blue-600">
                            {state.scopusId}{" "}
                          </p>{" "}
                        </Link>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" md:flex bg-gray-50  shadow-lg m-auto ">
              {/* side menu */}
              <div className="   md:w-1/4">
                <nav
                  aria-label="Main Nav"
                  class="flex flex-col  cursor-pointer  sticky top-0 bottom-20  space-y-1"
                >
                  <details class="group [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                      <span class="ml-3 text-xl font-bold "> Biosketch </span>

                      <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </summary>

                    <nav
                      aria-label="Users Nav"
                      class="flex flex-col mt-2 ml-8 space-y-1"
                    >
                      <p
                        onClick={() => setDtailCard("2")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Expertise
                      </p>
                      <p
                        onClick={() => setDtailCard("3")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Professional Background
                      </p>
                      <p
                        onClick={() => setDtailCard("4")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Administrative Positions
                      </p>
                      <p
                        onClick={() => setDtailCard("5")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Educational Details
                      </p>
                      <p
                        onClick={() => setDtailCard("1")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Personal Details
                      </p>
                      <p
                        onClick={() => setDtailCard("0")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Profile Links
                      </p>
                    </nav>
                  </details>
                  <details class="group [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                      <span class="ml-3 text-xl font-bold">Research</span>

                      <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </summary>

                    <nav
                      aria-label="Users Nav"
                      class="flex flex-col mt-2 ml-8 space-y-1"
                    >
                      <p
                        onClick={() => setDtailCard("6")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Publications
                      </p>

                      <p
                        onClick={() => setDtailCard("7")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Research Projects
                      </p>

                      <p
                        onClick={() => setDtailCard("8")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Collaborations
                      </p>
                    </nav>
                  </details>
                  <details class="group [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                      <span class="ml-3 text-xl font-bold">
                        Honours And Awards
                      </span>

                      <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </summary>

                    <nav
                      aria-label="Users Nav"
                      class="flex flex-col mt-2 ml-8 space-y-1"
                    >
                      <p
                        onClick={() => setDtailCard("9")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Honours
                      </p>

                      <p
                        onClick={() => setDtailCard("10")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Memberships
                      </p>
                    </nav>
                  </details>

                  <a
                    href="#award"
                    class="hidden items-center px-4 py-2 text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    <span class="ml-3 text-xl font-bold">
                      {" "}
                      Teaching Engagements
                    </span>
                  </a>

                  <details class="group hidden [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                      <span class="ml-3 text-xl font-bold">Supervision</span>

                      <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </summary>

                    <nav
                      aria-label="Users Nav"
                      class="flex flex-col mt-2 ml-8 space-y-1"
                    >
                      <p
                        onClick={() => setDtailCard("12")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Master Students
                      </p>

                      <p
                        onClick={() => setDtailCard("13")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        PhD Students
                      </p>
                    </nav>
                  </details>
                  <p
                    onClick={() => setDtailCard("14")}
                    class="flex items-center px-4 py-2 text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    <span class="ml-3 text-xl font-bold"> Study Materials</span>
                  </p>
                  <p
                    onClick={() => setDtailCard("18")}
                    class="flex items-center px-4 py-2 text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    <span class="ml-3 text-xl font-bold">Live Class</span>
                  </p>
                  <details class="group [&_summary::-webkit-details-marker]:hidden">
                    <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                      <span class="ml-3  text-xl font-bold">Miscellaneous</span>

                      <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </summary>

                    <nav
                      aria-label="Users Nav"
                      class="flex flex-col mt-2 ml-8 space-y-1"
                    >
                      <p
                        onClick={() => setDtailCard("15")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Events
                      </p>

                      <p
                        onClick={() => setDtailCard("16")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Visits
                      </p>
                      <p
                        onClick={() => setDtailCard("17")}
                        class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                      >
                        Miscellaneous
                      </p>
                    </nav>
                  </details>
                </nav>
              </div>

              <div className="m-4 w-11/12">
                {/* edu back */}
                <div className="pl-4">
                  <h1 className="text-4xl font-bold text-black">
                    {dtailCard <= 5 && <>BioSketch</>}
                  </h1>
                  {dtailCard == "0" ? (
                    <>
                      <ProfileId
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "2" ? (
                    <>
                      <Expertise
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "1" ? (
                    <>
                      <PersonalDetails
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "3" ? (
                    <>
                      <ProfessionalBackground
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "4" ? (
                    <>
                      <AdministrativePositions
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "5" ? (
                    <>
                      <EducationalDetails
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "6" ? (
                    <>
                      <Publication
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "7" ? (
                    <>
                      <ResearchProjects
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "8" ? (
                    <>
                      <Collab
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {/* edu back end */}
                </div>
                {/* professional start */}

                <div>
                  {dtailCard == "9" ? (
                    <Honours
                      loader={setLoader}
                      onClick={authFaculty}
                      data={state}
                    />
                  ) : null}
                  {dtailCard == "10" ? (
                    <Membership
                      loader={setLoader}
                      onClick={authFaculty}
                      data={state}
                    />
                  ) : null}
                  {dtailCard == "12" ? <MasterStudents /> : null}

                  {dtailCard == "13" ? (
                    <>
                      <Phd />
                    </>
                  ) : null}
                  {dtailCard == "14" ? (
                    <>
                      <StudyMaterials
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "18" ? (
                    <>
                      <AddClass
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "15" ? (
                    <>
                      <Event
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "16" ? (
                    <>
                      <Visit
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                  {dtailCard == "17" ? (
                    <>
                      <Misc
                        loader={setLoader}
                        onClick={authFaculty}
                        data={state}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FacultyDashboard;
