import React, { useState, useEffect } from "react";
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";
// //Datatable Modules
// import EditUserModal from './EditUserModal';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from "jquery";
//For API Requests
import axios from "axios";
import Breadcrumbs from "../../components/Breadcrumbs";
import Edit from "./Edit";
import Spinner from "../../page/facultydashboard/formGroup/Spinner";
import { message } from "antd";
// import Delete from './Delete';
const Table = () => {
  const [data, setState] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [deleteShowModal, setDeleteShowModal] = React.useState(false);
  const [passwordChangesModal, setPasswordChangesModal] = React.useState(false);
  const [editFaculty, setEditFaculty] = useState({});
  const [loader, setLoader] = useState(false);
  console.log("state", data.data);
  // State array variable to save and show data
  const fetchData = () => {
    axios.get("/api/v1/almuni-registration-data/accept").then((res) => {
      //Storing users detail in state array object
      setState({ data: res.data.almuniFeedback });
      console.log("dataAPI", res);
    });
    $(document).ready(function () {
      setTimeout(function () {
        $("#example").DataTable({
          pagingType: "full_numbers",
          pageLength: 5,
          processing: true,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
      }, 1000);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  console.log("new", data);

  // const handleEditChange = (id,  name, gender,email, mobileNo, dob, program, session, yearOfAdmission,photo) => {
  //     setShowModal(true)
  //     setEditFaculty({ id: id, photo:photo,name: name, gender:gender ,mobileNo: mobileNo, email: email, dob: dob, program: program, session: session, yearOfAdmission })
  // }
  const handleEditChange = (result) => {
    console.log(result);
    setShowModal(true);
    setEditFaculty(result);
  };
  const delAlumni = (id) => {
    setLoader(true);
    axios
      .delete(`/api/v1/alumini-feedback/accept/delete/${id}`)

      .then((response) => {
        setLoader(false);
        fetchData();
        message.success(`Deleted successfully`);
        setDelModalOpen(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
        setDelModalOpen(false);
      });
  };
  // .
  const [id2Delete, setId2Delete] = useState("");
  const [delModalOpen, setDelModalOpen] = useState(false);
  const handleModel = (e) => {
    setId2Delete(e);
    setDelModalOpen(true);
  };

  //Datatable HTML
  return (
    <>
      {loader && <Spinner />}
      <Breadcrumbs
        page={"Verified Alumni"}
        pagelink="#"
        sublink="#"
        subpage={"Alumni"}
      />
      <div className="flex flex-col" style={{ width: "100%" }}>
        {delModalOpen && (
          <div className="fixed inset-0 z-40 min-h-full overflow-y-auto overflow-x-hidden transition flex items-center">
            {/* overlay */}
            <div
              aria-hidden="true"
              className="fixed inset-0 w-full h-full bg-black/50 cursor-pointer"
            ></div>
            {/* Modal */}
            <div className="relative w-full cursor-pointer pointer-events-none transition my-auto p-4">
              <div className="w-full py-2 bg-white cursor-default pointer-events-auto  relative rounded-xl mx-auto max-w-sm">
                <button
                  onClick={() => setModalOpen(false)}
                  tabIndex={-1}
                  type="button"
                  className="absolute top-2 right-2 rtl:right-auto rtl:left-2"
                >
                  <svg
                    title="Close"
                    tabIndex={-1}
                    className="h-4 w-4 cursor-pointer text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="sr-only">Close</span>
                </button>
                <div className="space-y-2 p-2">
                  <div className="p-4 space-y-2 text-center text-black ">
                    <h2
                      className="text-xl font-bold tracking-tight"
                      id="page-action.heading"
                    >
                      Delete {id2Delete.name}
                    </h2>
                    <p className="text-gray-500">
                      Are you sure you would like to do this?
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <div
                    aria-hidden="true"
                    className="border-t dark:border-gray-700 px-2"
                  />
                  <div className="px-6 py-2">
                    <div className="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                      <button
                        type="button"
                        onClick={() => setDelModalOpen(false)}
                        className="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-gray-800 bg-white border-gray-300 hover:bg-gray-50 focus:ring-primary-600 focus:text-primary-600 focus:bg-primary-50 focus:border-primary-600 dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:text-gray-200 dark:focus:text-primary-400 dark:focus:border-primary-400 dark:focus:bg-gray-800"
                      >
                        <span className="flex items-center gap-1">
                          <span className="">Cancel</span>
                        </span>
                      </button>
                      <button
                        onClick={() => delAlumni(id2Delete._id)}
                        type="submit"
                        className="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-white shadow focus:ring-white border-transparent bg-red-600 hover:bg-red-500 focus:bg-red-700 focus:ring-offset-red-700"
                      >
                        <span className="flex items-center gap-1">
                          <span className="">Confirm</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <h1 className='flex justify-center pt-4 text-black  text-2xl  mb-0'>User list</h1> */}
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className=" overflow-x-auto">
              {/* <DataTable columns={columns} data={student} pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight='400px'
                                                highlightOnHover
                                            // actions={<button className='bg-blue-900 text-white pl-4 pr-4' style={{}}>{actionsMemo}</button>}
                                            /> */}
              <div className="MainDiv">
                <div className="container p-5">
                  {/* <div className='flex justify-end mr-8 '>
<button className='inline-block rounded border border-[#273272] px-12 py-2 text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500'>Add user</button>
           </div> */}
                  <table id="example" class="table table-hover table-bordered ">
                    <thead>
                      <tr class="text-black bg-neutral-200">
                        <th>Sr No.</th>
                        <th>Name</th>
                        <th>gender</th>
                        <th>Mobile No</th>
                        <th>Program</th>
                        <th>Status</th>

                        <th>View</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.data &&
                        data.data.map((result, index) => {
                          return (
                            <tr class="text-black ">
                              <td>{index + 1}</td>
                              <td>{result.name}</td>
                              <td>{result.gender}</td>
                              <td>{result.mobileNo}</td>
                              <td>{result.program} </td>
                              <td className="font-bold text-green-500">
                                {result.status}ed{" "}
                              </td>

                              <td>
                                <button
                                  onClick={() => {
                                    handleEditChange(result);
                                  }}
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  View
                                </button>
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    handleModel(result);
                                  }}
                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {showModal === true && (
                    <>
                      <Edit
                        showModal={showModal}
                        setShowModal={setShowModal}
                        editFaculty={editFaculty}
                        updateData={fetchData}
                      />
                    </>
                  )}{" "}
                  {/*
                                    {deleteShowModal === true && <>
                                        <Delete deleteShowModal={deleteShowModal} setDeleteShowModal={setDeleteShowModal} editFaculty={editFaculty} updateData={fetchData} />
                                    </>}

                                    </>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Table;
