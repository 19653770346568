import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

import LandingPreview from "./page/landing/LandingPreview";
import Notice from "./Notice";
import Data from "./page/Data";
import Profile from "./page/faculty/Profile";
import About from "./page/about/About";
import Research from "./page/research/Research";
import Student from "./page/faculty/Student";
import ResearchScholar from "./page/faculty/ResearchScholar";
import Msc from "./page/courses/Msc";
import Phd from "./page/courses/Phd";
import FacultyLogin from "./page/facultydashboard/FacultyLogin";
import FacultyDashboard from "./page/facultydashboard/FacultyDashboard";
import ImgGallery from "./page/landing/ImgGallery";
import StudyMaterial from "./page/studymaterial/StudyMaterial";
import PhdTable from "./page/studymaterial/PhdTable";
import PgTable from "./page/studymaterial/PgTable";
import MiscTable from "./page/studymaterial/MiscTable";
import StaffPreview from "./page/faculty/StaffPreview";
import NoticeFull from "./NoticeFull";
import Home from "./admin/Home";
import AddFacultyPreview from "./admin/adduser/AddFacultyPreview";
import AddstuPreview from "./admin/Addstudent/AddstuPreview";
import AddresPreview from "./admin/AddResearch/AddstuPreview";
import ViewUserPreview from "./admin/viewuser/ViewUserPreview";
import Slider from "./admin/slider/Slider";
import SliderPreview from "./admin/slider/SliderPreview";
import NoticePreview from "./admin/notice/NoticePreview";
import SiteTitlePreview from "./admin/site/SiteTitlePreview";
import AnnoucementPreview from "./admin/announcemet/AnnoucementPreview";
import AboutPreview from "./admin/about/AboutPreview";
import AboutDeptPreview from "./admin/about/AboutDeptPreview";
import ReportErrorPreview from "./admin/report/ReportErrorPreview";
import Alert from "./formGroup/Alert";
import Download from "./admin/download/Download";
import DownloadPreview from "./admin/download/DownloadPreview";
import SyllabusPreview from "./admin/syllabus/SyllabusPreview";
import GalleryPreview from "./admin/gallery/GalleryPreview";
import PrivateFaculty from "./privateRoute/PrivateFaculty";
import PrivateAdmin from "./privateRoute/PrivateAdmin";
import ForgotPassword from "./page/facultydashboard/ForgotPassword";
import FourOFourPage from "./formGroup/FourOFourPage";
import Card from "./admin/Card";
import DownloadMisc from "./page/download/DownloadMisc";
import SiteFooterPreview from "./admin/site/SiteFooterPreview";
import AddstaffPreview from "./admin/Addstaff/AddstaffPreview";
import axios from "axios";
import React, { useState } from "react";
import Spinner from "./page/facultydashboard/formGroup/Spinner";
import Logout from "./components/Logout";
import StatisticsPreview from "./page/Stats/StatisticsPreview";
import Alumni from "./page/alumni/Alumni";
import PAT from "./page/courses/PAT";
import NaacSsr from "./page/ssr/NaacSsr";
import NaacSsrPreview from "./page/ssr/NaacSsrPreview";
import AddResearchTopicPrev from "./admin/AddResearchTopic/AddResearchTopicPrev";
import AlumniForm from "./page/alumni/AlumniForm";
import ThankYou from "./page/alumni/ThankYou";
import NewRequestPreview from "./admin/alumni/NewRequestPreview";
import AlumniFeedBackPreview from "./admin/feedback/AlumniFeedBackPreview";
import TeacherFeedbackPreview from "./admin/feedback/TeacherFeedbackPreview";
import Seminar from './page/landing/Seminar';
import TeacherFeedback from "./page/feedback/TeacherFeedback";
import RejectedListPreview from "./admin/alumni/RejectedListPreview";
import AcceptedListPreview from "./admin/alumni/AcceptedListPreview";
import SSRPreview from "./admin/Ssr/SSRPreview";
import AlumniFeedBack from "./page/alumni/AlumniFeedBack";
import StudentFeedback from "./page/alumni/StudentFeedback";
import StudentFeedbackPreview from "./admin/feedback/StudentFeedbackPreview";
import LiveClass from "./page/liveClass/LiveClass";
import PlacementForm from "./page/alumni/PlacementForm";
import PlacementListPreview from "./admin/alumni/PlacementListPreview";
import ResetPassword from "./page/resetPassword/ResetPassword";
function App() {
  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(false);
  // console.log(data)
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        document.title = response.data[0] && response.data[0].siteName;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);
  return (
    <>
      {loader && <Spinner />}
      <HttpsRedirect>
        <BrowserRouter>
          <Routes>
            {/* // PUBLIC ROUTE */}
            <Route path="/faculty" element={<Data />} />
            <Route path="/faculty/profile/:id" element={<Profile />} />
            <Route path="/*" element={<FourOFourPage />} />
            <Route path="/" element={<LandingPreview />} />
            <Route path="/download" element={<DownloadMisc />} />
            <Route path="/card" element={<Card />} />
            <Route path="/statistics" element={<StatisticsPreview />} />
            <Route path="/alumni" element={<Alumni />} />
            <Route path="/pat-syllabus" element={<PAT />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/study-material" element={<StudyMaterial />} />
            <Route path="/phd-notes" element={<PhdTable />} />
            <Route path="/pg-notes/:semester" element={<PgTable />} />
            <Route path="/misc-notes" element={<MiscTable />} />
            <Route path="/staff" element={<StaffPreview />} />
            <Route path="/notice" element={<NoticeFull />} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/faculty/profile" element={<Profile />} />
            <Route path="/about" element={<About />} />
            <Route path="/research" element={<Research />} />
            <Route path="/gallery" element={<ImgGallery />} />
            <Route path="/student" element={<Student />} />
            <Route path="/msc-syllabus" element={<Msc />} />
            <Route path="/phd-syllabus" element={<Phd />} />
            <Route path="/login" element={<FacultyLogin />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/naacssr" element={<NaacSsrPreview />} />
            <Route path="/research-scholar" element={<ResearchScholar />} />
            <Route path="/alumni-registration" element={<AlumniForm />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/teacher-feedback" element={<TeacherFeedback />} />
            <Route path="/alumni-feedback" element={<AlumniFeedBack />} />
            <Route path="/student-feedback" element={<StudentFeedback />} />
            <Route path="/live-classes" element={<LiveClass />} />
            <Route path="/placement" element={<PlacementForm />} />
            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route path="/seminar" element={<Seminar />} />
            {/* // FACULTY ROUTE  */}
            <Route
              path="/faculty-dashboard"
              element={
                <PrivateFaculty>
                  <FacultyDashboard />
                </PrivateFaculty>
              }
            />

            {/* // ADMIN ROUTE */}
            <Route
              path="/admin/dashboard"
              element={
                <PrivateAdmin>
                  <Home />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/adduser"
              element={
                <PrivateAdmin>
                  <AddFacultyPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/viewuser"
              element={
                <PrivateAdmin>
                  <ViewUserPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/sliderchange"
              element={
                <PrivateAdmin>
                  <SliderPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/addnotice"
              element={
                <PrivateAdmin>
                  <NoticePreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/addannoucement"
              element={
                <PrivateAdmin>
                  <AnnoucementPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/sitetitle"
              element={
                <PrivateAdmin>
                  <SiteTitlePreview />
                </PrivateAdmin>
              }
            />
             <Route
              path="/admin/dashboard/SiteFooter"
              element={
                <PrivateAdmin>
                  <SiteFooterPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/add-research-topic"
              element={
                <PrivateAdmin>
                  <AddResearchTopicPrev />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/changeabout"
              element={
                <PrivateAdmin>
                  <AboutPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/download"
              element={
                <PrivateAdmin>
                  <DownloadPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/add-syllabus"
              element={
                <PrivateAdmin>
                  <SyllabusPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/addgallery"
              element={
                <PrivateAdmin>
                  <GalleryPreview />
                </PrivateAdmin>
              }
            />
            {/* <Route path='/research-scholar' element={
            <PrivateAdmin>
              <ResearchScholar />
            </PrivateAdmin>
          } /> */}
            <Route
              path="/dashboard/addstudents"
              element={
                <PrivateAdmin>
                  <AddstuPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/add/research"
              element={
                <PrivateAdmin>
                  <AddresPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/addstaff"
              element={
                <PrivateAdmin>
                  <AddstaffPreview />
                </PrivateAdmin>
              }
            />

            <Route
              path="/admin/dashboard/report-error"
              element={
                <PrivateAdmin>
                  <ReportErrorPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/new-request"
              element={
                <PrivateAdmin>
                  <NewRequestPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/alumni-feedback"
              element={
                <PrivateAdmin>
                  <AlumniFeedBackPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/student-feedback"
              element={
                <PrivateAdmin>
                  <StudentFeedbackPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/teacher-feedback"
              element={
                <PrivateAdmin>
                  <TeacherFeedbackPreview />
                </PrivateAdmin>
              }
            />

            <Route
              path="/admin/dashboard/rejected-list"
              element={
                <PrivateAdmin>
                  <RejectedListPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/accepted-list"
              element={
                <PrivateAdmin>
                  <AcceptedListPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/placement-list"
              element={
                <PrivateAdmin>
                  <PlacementListPreview />
                </PrivateAdmin>
              }
            />
            <Route
              path="/admin/dashboard/upload-ssr"
              element={
                <PrivateAdmin>
                  <SSRPreview />
                </PrivateAdmin>
              }
            />

            <Route
              path="/admin/dashboard/changeabout-landing"
              element={
                <PrivateAdmin>
                  <AboutDeptPreview data={datas} loader={setLoader} />
                </PrivateAdmin>
              }
            />
          </Routes>
        </BrowserRouter>
      </HttpsRedirect>
    </>
  );
}

export default App;
